import type {LinksFunction} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';
import cn from 'classnames';

import type {Handle} from '@/types';
import {Theme} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';
import blogStylesheet from '@/stylesheets/blog/blog.css?url';
import {EngineeringPageLayout} from '@/pages/shopify.engineering/components/EngineeringPageLayout/EngineeringPageLayout';
import {engineeringBlogLoader} from '@/pages/shopify.engineering/_index/server/loaders/loader.server';
import type {IndexSectionArticles} from '@/utils/server/blog/indexPageDefinitions/types.server';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import EngineeringHeroConversion from '@/pages/shopify.engineering/components/EngineeringHeroConversion/EngineeringHeroConversion';
import Hero from '@/components/pages/blog/Hero/Hero';
import ThreePane from '@/components/pages/blog/BlogIndex/sections/ThreePane';
import Conversion, {
  HeadingStyles,
  ButtonsStyles,
} from '@/pages/shopify.engineering/components/Conversion/Conversion';
import ArticleCarousel from '@/components/pages/blog/ArticleCarousel/ArticleCarousel';
import {EN_BLOG_PATH} from '@/components/blog/constants';
import {EngineeringBlogConversion} from '@/pages/shopify.engineering/components/EngineeringBlogConversion/EngineeringBlogConversion';
import {CAREERS_URL} from '@/pages/shopify.engineering/components/constants';

import {classNames} from '../styles/styles';

export const handle: Handle = {
  theme: Theme.Blog,
};

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

export const loader = engineeringBlogLoader;

export default function Engineering() {
  const {t} = useTranslations();

  const {
    latestArticles = [],
    mostReadArticles = [],
    category1Articles = [],
    localizedSettings,
    localizedLinks,
  } = useLoaderData<
    IndexSectionArticles & {
      localizedLinks: Record<string, any>;
    }
  >() ?? {};
  const blogType = BLOG_PAGE_TYPE.ENGINEERING;

  const heroConversionCta = latestArticles[0] && (
    <EngineeringHeroConversion
      {...t('conversionCta')}
      buttonHref={CAREERS_URL}
      componentName="home-CTA-block-email"
    />
  );

  return (
    <EngineeringPageLayout>
      <div className="relative pt-36">
        <Hero
          blogType={blogType}
          featuredArticle={latestArticles[0]}
          secondaryArticles={latestArticles.slice(1, 3)}
          enBlogPath={EN_BLOG_PATH.ENGINEERING}
          conversionCta={heroConversionCta}
        />
        <ThreePane
          topArticles={localizedSettings.threePane.topCards}
          topArticlesTarget="_blank"
          leftArticles={latestArticles.slice(-5)}
          rightArticles={category1Articles}
          heading={t('threePane.heading')}
          subHeading={t('threePane.subHeading')}
          leftSubHeading={t('threePane.leftSubHeading')}
          enBlogPath={EN_BLOG_PATH.ENGINEERING}
          blogType={blogType}
        />
        <div
          className={`border-y ${classNames.cardBorder} py-8 tablet:py-16 container`}
        >
          <Conversion
            {...t('conversion')}
            headingClassName={HeadingStyles}
            buttonsClassName={ButtonsStyles}
            buttons={[
              {
                href: localizedLinks.partnersSignup,
                external: true,
                text: t('conversion.buttonsGroup.primaryButton.text'),
              },
              {
                external: true,
                href: localizedLinks.partnersUrl,
                text: t('conversion.buttonsGroup.secondaryButton.text'),
              },
            ]}
          />
        </div>
        <ArticleCarousel
          {...t('mostRead')}
          blogType={blogType}
          primaryArticles={mostReadArticles}
          enBlogPath={EN_BLOG_PATH.ENGINEERING}
          carouselName="most-read"
          headingTag="h4"
          className={cn(
            classNames.title,
            '[&_.blogPost>div:first-child>a:not(:hover)]:text-engineering-highlight',
          )}
        />
        <EngineeringBlogConversion
          heading={t('sellAnywhere.heading')}
          subhead={t('sellAnywhere.subhead')}
          buttonHref={CAREERS_URL}
          buttonText={t('sellAnywhere.buttonText')}
          mode="dark"
          conversionWrapperStyles="[&_:not(a)]:text-[#120937] [&_button]:text-[#120937] [&_button]:text-white"
          componentName="cta-footer-banner"
        />
      </div>
    </EngineeringPageLayout>
  );
}
