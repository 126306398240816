import {twMerge} from 'tailwind-merge';

import type {HeadingAs, Mode} from '@/components/base/types';
import type {HeadingGroupContentProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import ButtonGroup, {
  type ButtonGroupContentProps,
} from '@/components/base/modules/ButtonGroup/ButtonGroup';

export * from './style';

interface ConversionProps
  extends HeadingGroupContentProps,
    ButtonGroupContentProps {
  className?: string;
  headingClassName?: string;
  buttonsClassName?: string;
  mode?: Mode;
}

export default function Conversion({
  headingClassName,
  buttonsClassName,
  mode = 'light',
  kicker,
  headingHtml,
  subheadHtml,
  buttons,
}: ConversionProps) {
  const headingGroupProps = {
    className: headingClassName,
    headingAs: 'span' as HeadingAs,
    kicker,
    headingHtml,
    subheadHtml,
    mode,
  };
  const buttonGroupProps = {
    className: twMerge('mt-md', buttonsClassName),
    buttons,
    mode,
    size: 'large' as 'large' | 'small',
  };
  return (
    <div className="flex justify-center flex-col">
      <HeadingGroup {...headingGroupProps} />
      <ButtonGroup {...buttonGroupProps} />
    </div>
  );
}
