import {cx} from 'class-variance-authority';

export const HeadingStyles = cx(
  'text-center dark-author-text text-[#e7ecfb] px-6 desktop:px-48',
  '[&_span]:font-medium',
  '[&_span]:font-aktivgroteskextended',
  '[&_span]:tracking-[-.02em] [&_span]:tablet:tracking-[-.01em]',
  '[&_span]:text-[34px] [&_span]:leading-[38px]',
  '[&_span]:tablet:text-[32px] [&_span]:tablet:leading-[40px]',
  '[&_span]:desktop:text-[40px] [&_span]:desktop:leading-[48px]',
  '[&_p]:pt-4 [&_p]:pb-6',
  '[&_p]:font-normal [&_p]:tracking-[-.02em]',
  '[&_p]:text-[18px] [&_p]:leading-[26px]',
  '[&_p]:tablet:text-xl',
  '[&_p]:desktop:text-[22px] [&_p]:desktop:leading-8',
  '[&_p]:max-w-prose [&_p]:mx-auto',
);
export const ButtonsStyles = cx(
  '[&_div]:justify-center text-[#faf9ff]',
  '[&_a]:px-[26px] [&_a]:py-[14px]',
  '[&_a:last-child]:text-[#e7ecfb] [&_a:last-child]:border-[#e7ecfb] [&_a:last-child:active]:border-[#e7ecfb] [&_a:last-child:active]:text-[#e7ecfb]',
  '[&_a:first-child]:bg-[#61D095] [&_a:first-child]:text-[#120937] [&_a:first-child:hover]:bg-[#62ba6b]',
  '[&_a:first-child:hover]:text-[#120937] [&_a:first-child:active]:bg-[#00740B] [&_a:first-child:active]:text-[#120937]',
  '[&_a:first-child:focus]:text-[#120937]',
);
