import Typography from '@/components/base/elements/Typography/Typography';
import Button from '@/components/base/elements/Button/Button';

interface EngineeringConversionProps {
  heading: string;
  description: string;
  buttonHref: string;
  buttonText: string;
  componentName?: string;
}

export default function EngineeringHeroConversion({
  heading,
  description,
  buttonHref,
  buttonText,
  componentName,
}: EngineeringConversionProps) {
  return (
    <div
      className="bg-[#e7ecfb] overflow-hidden relative"
      data-component-name={componentName}
    >
      <div className="tablet:py-9 tablet:px-14 p-6">
        <Typography className="text-center uppercase font-aktivgroteskextended mb-2 text-t5 font-bold text-[#120937]">
          {heading}
        </Typography>
        <Typography className="font-normal text-center text-sm mb-6 text-[#120937]">
          {description}
        </Typography>
        <div className="flex flex-col space-y-4 text-center tablet:block tablet:space-y-0 tablet:space-x-4">
          <Button
            className="text-white text-base"
            href={buttonHref}
            size="small"
            external={true}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}
